<template>
    <div>
        <panel class="test" :light="short">
            <h1 class="text-center title">
                等级考试<br/>
                <small>Grade examination</small>
            </h1>
            <b-container>
                <b-row>
                    <b-col lg="6">
                        <p><span class="text-danger">编程考级课程</span>面向学习过编程课程的学员，</p>
                        <p>以每年的模拟题为为主，进行课程设计；</p>
                        <p>每个级别共6节课</p>
                        <br/>
                        <p class="text-danger">累计10000+考级学员学习本课程，通过率<span style="font-size: 3rem;">98%</span></p>
                    </b-col>
                    <img src="https://static.makerfac.com/w/exam.png" class="col-lg-6">
                </b-row>
                <view-more v-if="!!short" to="/grade"/>
            </b-container>
        </panel>
        <panel light v-if="!short">
            <h1 class="text-center title">
                国内外各项竞赛<br/>
                <small>Various competitions at home and abroad</small>
            </h1>
            <b-container>
                <img src="https://static.makerfac.com/w/competitions.png">
            </b-container>
        </panel>
    </div>
</template>

<script>
import Panel from "@/components/Panel";
import ViewMore from "@/components/ViewMore";
export default {
    name: "index",
    components: {ViewMore, Panel},
    props: {
        short: Boolean
    },
    metaInfo: {
        title: '竞赛考级-【官网】美科教育少儿编程教育-STEAM教育-编程教育-创客教育-编程竞赛-教育服务品牌-美科编程',
        meta: [
            {
                name: 'keywords',
                content: '编程,美科,教育,少儿编程,美科编程,编程竞赛,STEAM,创客教育,机器人教育,教育培训'
            },
            {
                name: 'description',
                content: '【商务咨询:business@makerfac.com】美科教育专注打造一站式教培机构少儿编程教育,STEM教育,创客教育,编程竞赛的行业解决方案,快速复制办学服务能力。'
            }
        ]
    }
}
</script>

<style scoped>

</style>